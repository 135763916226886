<template>
	<div class="sw">
	<div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">Strategy Workshop</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-5" data-aos="fade-right">
            <br><br>
            <img src="images/undraw_shared_workspace_hwky.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-7 ml-auto pl-lg-7">
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Value Realization Workshop</h2>
            <p class="mb-4 text-black">We understand that identifying new paths to ROI and ensuring your efforts are sustainable, is a challenge to be met with new strategies.</p>

            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Digital Differentiator </h2>

            <p class="mb-4 text-black">Advances in digital business and technology like IIoT, Big data analytics, Process Automation using AI/ML hold great promise. </p>

            <p class="mb-4 text-black">However, there are critical questions that must be addressed such as ROI, novel partnerships, and sustained advantage. </p>

            <p class="mb-4 text-black">We apply our Edge to core to cloud consulting approach with open source tools and proprietary processes to strategic innovation in your industry and business processes to help you evaluate your digital positioning and help answer these questions.</p>
 
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Idea Monetizer</h2>

            <p class="mb-4 text-black">There are countless great ideas sitting dormant within organizations intangible assets that can be digitized to bring some insightful actions that still need to be explored. </p>
            <p class="mb-4 text-black">We apply our strategic innovation approach to help you monetize them including big idea prioritization, unlocking the value potential, data and process mapping and then pilot design and testing to transition into a full scale launch.</p>
 
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">Planning Workshop</h2>

            <p class="mb-4 text-black">Make your existing strategic planning process more innovative. The Planning workshop sessions brings breakthrough thinking to your most critical issues and opportunities to leverage data and cloud technologies in just three weeks through our value stream mapping  creative brainstorming  workshop to framing a real time challenge and solutions</p>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Advisory Consulting",
  components: {
    Footer
  }
};
</script>